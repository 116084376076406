html,
body {
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #2d2b2c;
}
.backgroundMneuMobie {
  display: none;
}
.ant-layout {
  background: transparent;
}
.ant-divider {
  border-top: 1px dashed #f0f0f0;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: 0px !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #fd8225 !important;
  position: relative;
  top: -3px;
  padding: 0;
}
.btnLoginMenu {
  border: none;
  border-radius: 50px;
  height: 40px;
  margin-top: 25px;
  width: 170px;
  background: transparent;
  font-size: 18px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  text-align: center;
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0 10px 6px #54D8A329;
}
.buttonHead {
  display: table;
  margin: 0 auto;
  margin-top: 50px;
}
.btnLogin {
  border: 2px solid #F5F6FA;
  border-radius: 50px;
  height: 56px;
  width: 205px;
  background: transparent;
  font-size: 21px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  margin-right: 30px;
  margin-left: 50px;
  text-align: center;
}
.BtnStartTest {
  border: none;
  border-radius: 50px;
  height: 56px;
  width: 205px;
  background: transparent;
  font-size: 21px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  text-align: center;
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0 10px 6px #54D8A329;
}
.container {
  width: 100%;
  margin: 0 auto;
}
.dropdownLang {
  margin-left: 0px !important;
}
.menuDropDown ul {
  width: 72px;
}
@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
#headerMain {
  background: url(/static/img/background.jpeg) no-repeat;
  background-size: cover;
  height: 800px;
  z-index: 9999999;
}
#headerMain img {
  cursor: pointer;
}
#headerMain .customerMenu {
  background: transparent;
  border: none;
  color: #F5F6FA;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
}
#headerMain .customerMenu a {
  color: #43425D;
  font-weight: 700;
  z-index: 99999;
  font-size: 14px;
}
#headerMain .customerMenu li a:active {
  color: #fd8225 !important;
}
#headerMain .customerMenu li a:hover {
  color: #fd8225;
}
#headerMain .langActive {
  width: 40px;
  margin-right: -15px;
  margin-left: 25px;
  margin-top: -5px;
}
#headerMain .dropdownLang {
  margin-left: 3px;
}
#headerMain .buttonLang {
  border: none;
  box-shadow: none;
}
#headerMain .imgHeaderMainMobi img {
  display: none;
}
#headerMain .menuPc {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
}
#headerMain .transparent {
  background: #fff;
  padding: 8px 0;
}
#headerMain .logo-main img {
  margin-top: 12px;
  width: 75%;
}
#headerMain .whitebg {
  z-index: 99999;
  width: 100%;
  max-width: 1584px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
}
#headerMain .whitebg a {
  font-weight: 500;
  font-weight: 700;
}
#headerMain .whitebg li a:hover {
  color: #fd8225;
}
#headerMain .ant-menu-item-selected {
  color: #fff;
  border: none;
  top: 2px;
}
#headerMain .customSelectLang .ant-select-selector {
  margin-top: 5px;
  background: transparent;
  border: none;
  color: #F5F6FA;
  margin-top: 30px;
}
#headerMain .customSelectLang .ant-select-arrow {
  margin-top: 5px;
  color: #fff;
}
#headerMain .TextContent h1 {
  color: #F5F6FA;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 200px;
  font-size: 28px;
  font-weight: 700;
}
#headerMain .TextContent p {
  color: #F5F6FA;
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  letter-spacing: 1.05px;
}
#headerMain .bannerImageHead {
  background: transparent url(/static/img/main-gadgets-empty.svg) 0% 0% no-repeat padding-box;
  background-size: cover;
  height: 723px;
  width: 64%;
  display: table;
  margin: 0 auto;
  margin-top: 40px;
}
#headerMain .bannerImag1 img {
  position: absolute;
  left: 517px;
  width: 36.3%;
  border: 3px solid #2d2d2d;
  border-radius: 18px;
}
#headerMain .bannerImag2 img {
  position: absolute;
  top: 42.5%;
  left: 373px;
  width: 4%;
  border: 2px solid #2d2d2d;
  border-radius: 12px;
}
#headerMain .bannerImag3 img {
  float: right;
  margin-top: 17%;
  margin-right: 31px;
  width: 10.7%;
  border: 1px solid #2d2d2d;
}
#banner-body-main-mqn {
  margin-top: 55px;
}
#banner-body-main-mqn .about-pro-main-mqn {
  padding: 100px 0px;
  margin-bottom: -100px;
}
#banner-body-main-mqn .about-pro-main-mqn p {
  font-size: 16px;
  text-align: justify;
  line-height: 29px;
  margin-bottom: 10px;
}
#banner-body-main-mqn .title-about-main .title {
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #2d2b2c;
}
#banner-body-main-mqn .title-about-main img {
  display: table;
  margin: 0 auto;
  width: 20%;
  margin-bottom: 40px;
}
#banner-body-main-mqn .video-main-about-mqn iframe {
  width: 100%;
  height: 330px;
  border: none;
}
#banner-body-main-mqn .item-title-services .title {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  margin-bottom: 35px;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-shadow: 0 2px 4px #1b1b1b;
}
#banner-body-main-mqn .item-title-services img {
  display: table;
  margin: 0 auto;
  width: 20%;
  margin-top: -20px;
  margin-bottom: 20px;
}
#banner-body-main-mqn .backborder {
  background: #eb7d1666;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: table;
  margin: 0 auto;
  position: inherit;
  z-index: 99999;
  margin-left: -5px;
}
#banner-body-main-mqn .backPro {
  background: #fb7c1396 0% 0% no-repeat padding-box;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}
#banner-body-main-mqn .contentTextReview {
  text-align: center;
  background: #fb7100 0% 0% no-repeat padding-box;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: table;
  margin: 0 auto;
  opacity: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 0px;
}
#banner-body-main-mqn .contentTextReview h1 {
  text-align: center;
  font-size: 40px;
  color: #F5F6FA;
  margin-bottom: -14px;
  padding: 8px 0;
  text-shadow: 0 2px 4px #1b1b1b;
  font-weight: 700;
  letter-spacing: 1px;
}
#banner-body-main-mqn .contentTextReview h2 {
  text-align: center;
  font-size: 13px;
  color: #F5F6FA;
  opacity: 1;
  letter-spacing: 0.9px;
  padding: 0 13px;
  font-weight: 700;
  line-height: 19px;
  text-shadow: 0 2px 4px #1b1b1b;
}
#banner-body-main-mqn .line-rate-mqn-main {
  border-top: 4px dashed #f37c1bc7;
  position: relative;
  top: -95px;
  width: 75%;
  left: 125px;
}
#banner-body-main-mqn .item-rate-product-main {
  margin-top: 40px;
}
#banner-body-main-mqn .item-background-serviecs {
  background: #FE7E17;
  width: 100%;
  height: 295px;
  border-radius: 10px;
  opacity: 0.9;
}
#banner-body-main-mqn .item-background-serviecs h1 {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 1.5px;
  padding: 0 24px;
  text-shadow: 0 2px 4px #1b1b1b;
}
#banner-body-main-mqn .item-serviecs-main {
  text-transform: uppercase;
  position: relative;
  top: 14%;
}
#banner-body-main-mqn .item-serviecs-main img {
  width: 28%;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
}
#banner-body-main-mqn .item-content-about-main h1 {
  text-transform: uppercase;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-weight: 700;
}
#banner-body-main-mqn .content-detail-about-us-mqn img {
  width: 30px;
  margin-top: -2px;
  margin-right: 10px;
  margin-left: 10px;
}
#banner-body-main-mqn .content-detail-about-us-mqn span {
  position: relative;
  top: -10px;
  color: #2d2b2c;
}
#banner-body-main-mqn .content-detail-about-us-mqn h3 {
  background: #fe7e17;
  width: 33%;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 16px;
}
#banner-body-main-mqn .content-detail-about-us-mqn h3:before {
  content: '';
  position: absolute;
  left: calc(36% - 36px);
  width: 0;
  height: 0;
  margin-top: -10px;
  border-top: solid 50px #d06916;
  border-right: solid 28px transparent;
}
#banner-body-main-mqn .item-content-map-company-main {
  top: 180px;
  position: relative;
}
#banner-body-main-mqn .sologanMqn {
  background: #fe7e17;
  width: 95%;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
#banner-body-main-mqn .sologanMqn:before {
  content: '';
  position: absolute;
  left: calc(97% - 24px);
  width: 0;
  height: 0;
  margin-top: -10px;
  border-top: solid 40px #d06916;
  border-right: solid 28px transparent;
}
#banner-body-main-mqn .services-banner-main-mqn {
  background: url(/static/img/img4.jpeg) no-repeat;
  background-attachment: fixed;
  height: 100%;
  background-size: cover;
  padding: 50px 0;
}
#banner-body-main-mqn .testimonials-main-banner-mqn {
  background: url(/static/img/background-customer.jpeg) no-repeat;
  background-attachment: fixed;
  height: 100%;
  background-size: 100% 100%;
  padding: 20px;
  margin-top: 80px;
}
#banner-body-main-mqn .title-main-testimonials {
  margin-top: 60px;
}
#banner-body-main-mqn .banner-logo-parter-mqn-main img {
  width: 200px;
  height: 70px;
  object-fit: contain;
}
#banner-body-main-mqn .banner-logo-parter-mqn-main {
  margin-top: 20px;
}
#banner-body-main-mqn .title-main-testimonials .title {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 2px 4px #1b1b1b;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
}
#banner-body-main-mqn .title-main-testimonials img {
  display: table;
  margin: 0 auto;
  width: 20%;
}
#banner-body-main-mqn .item-testimonoals {
  margin-top: 50px;
}
#banner-body-main-mqn .item-content-company-custormer-mqn h3 {
  font-weight: 700;
}
#banner-body-main-mqn .item-info-testimoals {
  border-bottom: 1px dashed #fff;
  margin: 0 10px;
  height: 215px;
  margin-bottom: 40px;
  cursor: pointer;
}
#banner-body-main-mqn .item-info-testimoals .company {
  font-size: 18px;
  color: #2d2b2c;
}
#banner-body-main-mqn .none-border {
  border: none;
}
#banner-body-main-mqn .NameCustomer {
  color: #fff;
}
#banner-body-main-mqn .product-main-pro-mqn-main {
  margin-bottom: -70px;
  padding: 70px 0px;
  background: #fdfdfd;
}
#banner-body-main-mqn .title-banner-product .title {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #2d2b2c;
}
#banner-body-main-mqn .title-banner-product img {
  display: table;
  margin: 0 auto;
  width: 20%;
}
#banner-body-main-mqn .title-banner-product p {
  font-size: 22px;
  width: 63%;
  display: table;
  margin: 20px auto;
  text-align: center;
}
#banner-body-main-mqn .item-product-mqn-pro {
  background: url(/static/img/ZZZZZ30155.jpeg) no-repeat;
  background-size: 100% 100%;
  height: 260px;
  z-index: 999999;
  border-radius: 5px 5px 0 0;
}
#banner-body-main-mqn .item-product-mqn-pro img {
  width: 140px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
#banner-body-main-mqn .item-title-product {
  position: relative;
  top: 13%;
}
#banner-body-main-mqn .fiup {
  top: 5%;
}
#banner-body-main-mqn .fiup img {
  width: 86px;
  margin-bottom: -10px;
}
#banner-body-main-mqn .mediaProduct {
  position: relative;
  top: 13%;
}
#banner-body-main-mqn .mediaProduct img {
  width: 50px;
  margin-bottom: 10px;
}
#banner-body-main-mqn .item-product-mqn-pro h1 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
  text-shadow: 0 2px 4px #1b1b1b;
  letter-spacing: 1.5px;
}
#banner-body-main-mqn .item-content-pro-mqn {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  height: 375px;
}
#banner-body-main-mqn .item-content-pro-mqn button {
  width: 100%;
  color: #fd8225;
  background: #fff;
  text-transform: uppercase;
  font-weight: 700;
  height: 48px;
  font-size: 16px;
  border-radius: 25px;
  transition: 0.1s;
  border: 2px solid #fd8225;
  position: absolute;
  bottom: 10px;
}
#banner-body-main-mqn .itemFixMainProduct {
  height: 373px;
  position: relative;
  width: 97%;
}
#banner-body-main-mqn .item-content-pro-mqn button:hover {
  border: 2px solid #fd8225;
  color: #fff;
  background: #fd8225;
}
#banner-body-main-mqn .item-content-pro-mqn p {
  background: #fff;
  font-size: 14px;
  text-align: justify;
}
#banner-body-main-mqn .item-content-pro-mqn ul {
  margin-top: -10px;
  padding: 10px;
}
#banner-body-main-mqn .item-content-pro-mqn ul li {
  list-style-type: none;
}
#banner-body-main-mqn .item-content-pro-mqn img {
  width: 32px;
  float: left;
  margin-right: 10px;
}
#banner-body-main-mqn .item-pro-mqn {
  box-shadow: 0 3px 6px #9E9E9E;
  border-radius: 5px;
  margin-top: 30px;
}
#banner-body-main-mqn .item-info-testimoals button {
  float: right;
  color: #FE7E17;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
}
#banner-body-main-mqn .item-info-testimoals img {
  border-radius: 50%;
  width: 100%;
}
#banner-body-main-mqn .item-info-testimoals .info {
  font-size: 18px;
  font-weight: 700;
  color: #2d2b2c;
}
#banner-body-main-mqn .item-info-testimoals .description {
  text-align: justify;
  font-size: 16px;
  color: #2d2b2c;
}
#banner-body-main-mqn .ant-carousel .slick-dots li.slick-active button,
#banner-body-main-mqn .ant-carousel .slick-dots li.slick-active,
#banner-body-main-mqn .ant-carousel .slick-dots li {
  background: #FE7E17;
}
#banner-body-main-mqn .item-new-main-pro-mqn {
  margin-top: 60px;
}
#banner-body-main-mqn .avata-news {
  height: 200px;
  overflow: hidden;
}
#banner-body-main-mqn .avata-news img {
  transition: transform 0.5s ease;
}
#banner-body-main-mqn .avata-news img:hover {
  transform: scale(1.5);
}
#banner-body-main-mqn .datetime-news-mqn img {
  width: 20px;
  padding: 2px;
  float: left;
  margin-right: 5px;
}
#banner-body-main-mqn .item-news-main {
  margin-top: 40px;
}
#banner-body-main-mqn .datetime-news-mqn span {
  float: right;
  background: #FE7E17;
  padding: 3px 16px;
  color: #fff;
  border-radius: 15px;
  margin-top: -5px;
  font-weight: 700;
}
#banner-body-main-mqn .title-news-main h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
}
#banner-body-main-mqn .datetime-news-mqn {
  margin-bottom: 60px;
}
#banner-body-main-mqn .datetime-news-mqn p {
  font-size: 14px;
  color: #656D78;
  font-style: italic;
  float: left;
}
#banner-body-main-mqn .item-news-mqn {
  margin-bottom: 30px;
  cursor: pointer;
}
#banner-body-main-mqn .item-news-mqn h1 {
  line-height: 28px;
  text-align: justify;
  margin-top: 10px;
  white-space: nowrap;
  width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}
#banner-body-main-mqn .item-news-main > .ant-carousel .slick-dots-bottom {
  bottom: -30px;
}
.item-detail-content-customer .nameModalMqn {
  color: #FE7E17;
  font-weight: 700;
  font-size: 22px;
}
.item-detail-content-customer .companyModal {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.item-detail-content-customer .infoModal {
  font-weight: 700;
  text-align: center;
}
.item-detail-content-customer .nameModal {
  color: #fe7e17;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.item-detail-content-customer h4 {
  text-align: center;
  font-size: 18px;
}
.avataDEtailCustomer {
  width: 10%;
  border-radius: 50%;
  float: left;
  margin-right: 25px;
}
.item-detail-blog-main-mqn img {
  width: 100%;
}
#footter-main-pro-mqn {
  background-attachment: fixed;
  background: url(/static/img/background-footer.jpeg) no-repeat;
  background-size: 100% 100%;
  margin-top: 20px;
  height: 100%;
  padding: 50px 0;
}
#footter-main-pro-mqn .title-contact-main-pro .title {
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px #1b1b1b;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.5px;
}
#footter-main-pro-mqn .title-contact-main-pro img {
  display: table;
  margin: 0 auto;
  width: 20%;
  margin-bottom: 30px;
}
#footter-main-pro-mqn .item-content-footter-pro-mqn p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}
#footter-main-pro-mqn .form-contact-main-pro-mqn input {
  height: 39px;
}
#footter-main-pro-mqn .form-contact-main-pro-mqn button {
  float: right;
  height: 40px;
  padding: 0 40px;
  font-size: 18px;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#footter-main-pro-mqn .item-company-adrress-mqn {
  margin-top: 15px;
}
#footter-main-pro-mqn .item-company-adrress-mqn img {
  width: 32px;
  background: #FE7E17;
  padding: 4px;
  border-radius: 5px;
  float: left;
  margin-right: 9px;
  margin-top: 4px;
}
#footter-main-pro-mqn .item-company-adrress-mqn p {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  text-shadow: 0 2px 4px #1b1b1b;
  margin-bottom: 30px;
}
#footter-main-pro-mqn .FixBottom {
  height: 40px;
}
#footter-main-pro-mqn .item-address-pro h1 {
  color: #fff;
  font-weight: 700;
}
#footter-main-pro-mqn .address-company-main-pro iframe {
  border: none;
  margin-top: 8px;
}
#footter-main-pro-mqn .fixTopContent {
  position: relative;
  top: 10px;
}
.copyRightMqn p {
  background: #FE7E17;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  margin-top: -1px;
}
.detail-camera-ai-main-mqn h1 {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.detail-camera-ai-main-mqn h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  color: #ff9c40;
}
.detail-camera-ai-main-mqn h3 {
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 25px;
  margin-top: 40px;
}
.detail-camera-ai-main-mqn p {
  font-size: 16px;
}
.detail-camera-ai-main-mqn img {
  width: 100%;
  height: 200px;
}
.detail-camera-ai-main-mqn h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.detail-camera-ai-main-mqn iframe {
  width: 100%;
  height: 200px;
}
.slider-img-header img {
  width: 100%;
  object-fit: cover;
}
.moving-mouse-holder {
  margin: auto;
  margin-top: 60px;
  width: 170px;
}
.moving-mouse-holder .mouse {
  width: 26px;
  height: 40px;
  position: relative;
  right: 0;
  border-radius: 18px;
  border: 2px solid #fe7e17;
  display: table;
  margin: 0 auto;
  background: #fe7e17;
}
.moving-mouse-holder .mouse-button {
  background-color: #fff;
  width: 4px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0 0 0 -2px;
  animation: mouse-scroll 0.7s infinite alternate;
}
.moving-mouse-holder .text {
  margin-top: -30px;
  margin-left: 40px;
  color: #3c6;
  font-size: 12px;
  line-height: 1em;
}
@keyframes mouse-scroll {
  to {
    transform: translate(0, 6px) scale(1, 0.8);
  }
}
.mouse-scroll-main-mqn {
  margin-top: -150px;
  position: relative;
}
.slick-dots,
.slick-arrow {
  display: none !important;
}
.detail-about-us-mqn-main {
  background: url(/static/img/backgroundAboutUs1.jpeg) no-repeat;
  background-size: contain;
  background-position: right top 15vh;
  height: 700px;
}
.content-detail-about-us-mqn {
  margin-top: -100px;
}
.content-detail-about-us-mqn h1 {
  color: #FE7E17;
  text-align: center;
  padding: 12px;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
}
.content-detail-about-us-mqn h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}
.content-detail-about-us-mqn ul {
  margin-bottom: 20px;
}
.content-detail-about-us-mqn ul li {
  list-style-image: url(/static/img/company.jpeg);
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
}
.ant-modal-content {
  z-index: 9999999999 !important;
  position: relative !important;
}
.noneHover:hover {
  border-color: white !important;
  outline: none !important;
}
.ant-drawer-left.ant-drawer-open {
  display: none;
}
.ant-notification {
  top: 80px !important;
  z-index: 99999;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
@media (max-width: 1199px) {
  #home #headerMain {
    height: 600px;
  }
  #home #headerMain .logo-main img {
    margin-top: 26px;
    width: 83%;
  }
  #home #headerMain .langActive {
    width: 35px;
    margin-right: -3px;
    margin-left: 10px;
    height: 25px;
  }
  #home #headerMain .customerMenu a {
    margin-right: -16px;
  }
  #home #banner-body-main-mqn .video-main-about-mqn iframe {
    height: 285px;
  }
  #home #banner-body-main-mqn .testimonials-main-banner-mqn {
    background-size: cover;
  }
  #home #banner-body-main-mqn .item-content-map-company-main {
    top: 0;
  }
  #home #banner-body-main-mqn .item-news-mqn h1 {
    width: 300px;
  }
  #home #banner-body-main-mqn .about-pro-main-mqn p {
    line-height: 20px;
    font-size: 14px;
  }
  #home #banner-body-main-mqn .sologanMqn {
    font-size: 16px;
    width: 100%;
  }
  #home #banner-body-main-mqn .sologanMqn:before {
    display: none;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h3 {
    width: 40%;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h3:before {
    left: calc(41% - 11px);
  }
  #home #banner-body-main-mqn .itemFixMainProduct {
    height: 435px;
  }
  #home #banner-body-main-mqn .banner-logo-parter-mqn-main img {
    width: 170px;
  }
  #home #banner-body-main-mqn .backborder {
    margin-left: 70px;
  }
  #home .avataDEtailCustomer {
    width: 40%;
  }
  #home .detail-camera-ai-main-mqn img {
    margin-bottom: 20px;
  }
  #home .itemMapMobile {
    position: relative;
    top: 8px;
  }
  #home .detail-about-us-mqn-main {
    height: fit-content;
    background-size: contain;
    background-position: right top 0vh;
  }
  #home .content-detail-about-us-mqn {
    margin-top: 0px;
  }
  #home #footter-main-pro-mqn {
    background-size: cover;
  }
  .ant-menu.ant-menu-dark {
    color: white;
    background: #fe7e17;
    height: 100%;
    font-weight: bold;
    font-size: 18px;
    margin-top: 28%;
  }
  .langActive {
    margin-left: 25px;
    margin-right: -15px;
    height: 30px;
  }
  .ant-menu-dark .ant-menu-item > a {
    color: white;
  }
  .avataDEtailCustomer {
    width: 40% !important;
    display: table !important;
    margin: 0 auto 20px !important;
    float: none !important;
  }
  .item-detail-content-customer h2 {
    text-align: center !important;
  }
  .item-detail-content-customer p {
    text-align: center !important;
  }
  .ant-modal-content {
    z-index: 9999999999 !important;
    position: relative !important;
  }
  .ant-drawer-left.ant-drawer-open {
    display: block;
  }
}
@media (max-width: 991px) {
  .langActive {
    width: 38px;
    margin-top: 10px;
    margin-left: 0px;
    height: 25px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0px !important;
  }
  #home {
    overflow: hidden;
  }
  #home .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }
  #home .ant-menu.ant-menu-dark,
  #home .ant-menu-dark .ant-menu-sub {
    color: white;
    background: #FE7E17;
    height: 100%;
    min-height: 104.5vh;
    font-weight: bold;
    font-size: 20px;
    margin-top: 0px;
  }
  #home .ant-btn {
    width: auto;
    height: 50px;
    margin-top: 8px;
  }
  #home .lang-mobile {
    float: right;
    margin-top: 6%;
  }
  #home #headerMain .menuPc {
    display: none;
  }
  #home #headerMain .backgroundMneuMobie {
    display: block;
    padding: 3px 5px;
    z-index: 9999;
    background: white;
    width: 102.5%;
  }
  #home #headerMain .logo img {
    width: 68%;
    display: table;
    margin: 0 auto;
  }
  #home #headerMain .langActive {
    margin-right: 5px;
    height: 22px;
    margin-left: 8px;
    margin-top: -2px;
  }
  #home #banner-body-main-mqn .about-pro-main-mqn {
    padding: 36px 24px;
    margin-bottom: 0;
  }
  #home #banner-body-main-mqn .about-pro-main-mqn p {
    font-size: 16px;
    line-height: 28px;
  }
  #home #banner-body-main-mqn .item-content-pro-mqn button {
    left: 5px;
    bottom: 12px;
  }
  #home #banner-body-main-mqn .product-main-pro-mqn-main {
    padding: 70px 24px;
  }
  #home #banner-body-main-mqn #news {
    padding: 0px 24px;
  }
  #home #banner-body-main-mqn .sologanMqn {
    width: 100%;
    font-size: 20px;
  }
  #home #banner-body-main-mqn .banner-logo-parter-mqn-main img {
    width: 100%;
    height: 55px;
  }
  #home #banner-body-main-mqn .sologanMqn:before {
    left: calc(97% - 13px);
    border-top: solid 52px #d06916;
  }
  #home #banner-body-main-mqn .backborder {
    margin-left: 100px;
    z-index: 0;
  }
  #home #banner-body-main-mqn .item-rate-mobile {
    margin-left: 70%;
  }
  #home #banner-body-main-mqn .item-rate-mobile2 {
    margin-left: 35%;
  }
  #home #banner-body-main-mqn .contentTextReview h2 {
    font-size: 14px;
  }
  #home #banner-body-main-mqn .item-background-serviecs h1 {
    font-size: 28px;
  }
  #home #banner-body-main-mqn .contentTextReview h1 {
    font-size: 36px;
    margin-bottom: -16px;
    padding: 17px 0px;
  }
  #home #banner-body-main-mqn .item-serviecs-main {
    top: 0;
  }
  #home #banner-body-main-mqn .item-serviecs-main img {
    width: 20%;
  }
  #home #banner-body-main-mqn .line-rate-mqn-main {
    display: none;
  }
  #home #banner-body-main-mqn .item-background-serviecs {
    height: 90%;
    padding: 32px 20px;
    width: 90%;
    margin: 0 auto;
  }
  #home #banner-body-main-mqn .none-border {
    border-bottom: 1px dashed white;
  }
  #home #banner-body-main-mqn .item-info-testimoals img {
    margin-top: -15px;
    width: 90%;
  }
  #home #banner-body-main-mqn .video-main-about-mqn iframe {
    height: 360px;
  }
  #home #banner-body-main-mqn .item-content-about-main h1 {
    font-size: 24px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h3 {
    width: 55%;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h3:before {
    left: calc(57% - 15px);
  }
  #home #banner-body-main-mqn .itemFixMainProduct {
    height: 600px;
  }
  #home #banner-body-main-mqn .banner-logo-parter-mqn-main img {
    width: 100%;
  }
  #home .avataDEtailCustomer {
    display: block;
    margin: 30px;
    margin: 0 auto;
    float: none;
  }
  #home #footter-main-pro-mqn {
    height: 100%;
    background-size: cover;
    padding: 24px;
  }
  #home .ant-menu-dark .ant-menu-item > a {
    color: white;
  }
  #home .detail-camera-ai-main-mqn img {
    object-fit: cover;
  }
  #home .itemMapMobile {
    top: 0px;
  }
  .ant-select-item-option-content img {
    display: table;
    margin: 10px auto;
  }
  .ant-drawer-body {
    overflow-y: hidden;
  }
  .ant-modal-content {
    z-index: 9999999999 !important;
    position: relative !important;
  }
  .ant-drawer-left.ant-drawer-open {
    display: block;
  }
}
@media (max-width: 575px) {
  .avataDEtailCustomer {
    width: 50% !important;
    border-radius: 50%;
    margin-bottom: 20px !important;
  }
  .item-detail-content-customer h2 {
    font-size: 18px;
    padding-top: 14px;
    margin-bottom: 10px;
  }
  .item-detail-content-customer h3 {
    font-size: 14px;
    margin-left: 0px;
    text-align: center;
  }
  .item-detail-content-customer h4 {
    font-size: 14px;
    margin-left: 0px;
    text-align: center;
  }
  .item-detail-content-customer p {
    text-align: justify !important;
  }
  .item-content-company-custormer-mqn {
    position: relative;
    right: 58%;
  }
  .ant-menu.ant-menu-dark {
    height: 101.5vh;
    margin-top: 24%;
    overflow-y: hidden !important;
  }
  .ant-menu.ant-menu-dark li {
    margin-top: 30px;
  }
  .ant-modal-title {
    font-size: 14px;
    font-weight: bold;
  }
  #home {
    margin-right: 0;
  }
  #home .detail-about-us-mqn-main {
    background: none;
  }
  #home .lang-mobile {
    margin-top: 10%;
  }
  #home .ant-btn {
    margin-top: 2px;
  }
  #home .copyRightMqn p {
    font-size: 14px;
    margin-top: 0px;
  }
  #home #headerMain .backgroundMneuMobie {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 3px 7px #8080804d;
  }
  #home #headerMain .langActive {
    margin-right: 5px;
    height: 22px;
    margin-left: 8px;
    margin-top: -3px;
  }
  #home #headerMain .MenuMobie {
    padding: 0px;
  }
  #home #headerMain .logo img {
    width: 100%;
    margin-left: -15px;
    margin-top: 5px;
  }
  #home #banner-body-main-mqn .item-info-testimoals img {
    border-radius: 50%;
    width: 50%;
    display: table;
    margin: 0 auto 25px;
  }
  #home #banner-body-main-mqn .itemFixMainProduct {
    height: 100%;
  }
  #home #banner-body-main-mqn .services-banner-main-mqn {
    margin-right: 0;
  }
  #home #banner-body-main-mqn #product {
    padding: 70px 24px;
  }
  #home #banner-body-main-mqn .contentTextReview {
    top: 10px;
    left: 10px;
  }
  #home #banner-body-main-mqn .contentTextReview h1 {
    padding: 12px 0px;
  }
  #home #banner-body-main-mqn .item-news-mqn h1 {
    width: 100%;
    font-size: 22px;
  }
  #home #banner-body-main-mqn .background-white-content p {
    font-size: 18px;
  }
  #home #banner-body-main-mqn .background-white-content .datetime-news-mqn p {
    font-size: 14px;
  }
  #home #banner-body-main-mqn .about-pro-main-mqn {
    padding: 70px 12px;
    margin-bottom: -50px;
  }
  #home #banner-body-main-mqn .about-pro-main-mqn p {
    line-height: 24px;
    font-size: 16px;
  }
  #home #banner-body-main-mqn .item-info-testimoals {
    border-bottom: 1px dashed white;
    margin: 0px 10px;
    height: 100%;
    margin-bottom: 0px;
    padding: 30px 0px;
  }
  #home #banner-body-main-mqn .item-content-pro-mqn p {
    background: #fff;
    font-size: 18px;
    line-height: 32px;
  }
  #home #banner-body-main-mqn .item-content-pro-mqn ul {
    margin-bottom: 40px;
  }
  #home #banner-body-main-mqn .item-info-testimoals h1 {
    font-size: 18px;
    text-align: center;
  }
  #home #banner-body-main-mqn .item-info-testimoals p {
    text-align: center;
    font-size: 18px;
  }
  #home #banner-body-main-mqn .item-info-testimoals h2 {
    font-size: 16px;
    text-align: center;
  }
  #home #banner-body-main-mqn .slide-testimonials-main-pro-mqn {
    margin-top: -40px;
  }
  #home #banner-body-main-mqn .title-banner-product h1 {
    font-size: 31px;
  }
  #home #banner-body-main-mqn .title-banner-product p {
    padding: 0px 24px;
    width: 100%;
  }
  #home #banner-body-main-mqn .item-title-services img {
    width: 70%;
  }
  #home #banner-body-main-mqn .banner-logo-parter-mqn-main img {
    width: 100%;
    height: 50px;
  }
  #home #banner-body-main-mqn .none-border {
    display: block;
  }
  #home #banner-body-main-mqn .video-main-about-mqn iframe {
    height: 100%;
  }
  #home #banner-body-main-mqn .sologanMqn:before {
    display: none;
  }
  #home #banner-body-main-mqn .title-about-main img {
    width: 70%;
  }
  #home #banner-body-main-mqn .sologanMqn {
    width: 100%;
    font-size: 18px;
  }
  #home #banner-body-main-mqn .item-title-services h1 {
    font-size: 24px;
  }
  #home #banner-body-main-mqn .backborder,
  #home #banner-body-main-mqn .item-rate-mobile2,
  #home #banner-body-main-mqn .item-rate-mobile {
    display: table;
    margin: 0 auto 30px;
    z-index: 0;
  }
  #home #banner-body-main-mqn .title-banner-product img {
    width: 65%;
  }
  #home #banner-body-main-mqn .title-main-testimonials img {
    width: 70%;
  }
  #home #banner-body-main-mqn .item-content-about-main h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  #home #banner-body-main-mqn .item-serviecs-main img {
    width: 25%;
    margin-bottom: 20px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h2 {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h3 {
    font-size: 16px;
    width: 100%;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn ul li {
    font-size: 16px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn .item-background-serviecs h1 {
    font-size: 21px;
  }
  #home #footter-main-pro-mqn .title-contact-main-pro img {
    width: 60%;
  }
  #home #footter-main-pro-mqn .item-company-adrress-mqn p {
    font-size: 18px;
  }
  #home #footter-main-pro-mqn .form-contact-main-pro-mqn textarea,
  #home #footter-main-pro-mqn .form-contact-main-pro-mqn input {
    font-size: 16px;
  }
  #home .detail-camera-ai-main-mqn h3,
  #home .detail-camera-ai-main-mqn h1 {
    font-size: 19px;
  }
  #home .detail-camera-ai-main-mqn h2 {
    font-size: 14px;
  }
  #home .content-detail-about-us-mqn h1 {
    font-size: 25px;
  }
  #home .content-detail-about-us-mqn span {
    top: -10px;
  }
  #home .content-detail-about-us-mqn h3 {
    width: 100%;
  }
  #home .content-detail-about-us-mqn h3:before {
    display: none;
  }
  #home .item-detail-blog-main-mqn h1 {
    font-size: 22px;
    font-weight: bold;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: none;
  }
  .ant-select-item-option-content {
    font-size: 16px;
  }
  .ant-select-item-option-content img {
    display: table;
    margin: 10px auto;
    font-size: 16px;
  }
  .ant-drawer-body {
    overflow-y: hidden;
  }
  .ant-modal-content {
    z-index: 9999999999 !important;
    position: relative !important;
  }
  .ant-drawer-left.ant-drawer-open {
    display: block;
  }
  .moving-mouse-holder {
    margin-top: 0px;
    padding: 54px;
  }
  .ant-select-item {
    padding-top: 10px;
  }
}
@media (max-width: 320px) {
  #home .lang-mobile {
    margin-top: 8%;
  }
  #home #banner-body-main-mqn .item-background-serviecs h1 {
    font-size: 24px;
  }
  #home #banner-body-main-mqn .video-main-about-mqn video {
    height: 100%;
  }
  #home #banner-body-main-mqn .item-content-about-main h1 {
    font-size: 26px;
  }
  #home #banner-body-main-mqn .itemFixMainProduct button {
    margin-top: 0px;
  }
  #home #banner-body-main-mqn .itemFixMainProduct {
    height: auto;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn img {
    margin-left: -5px;
    margin-right: 0px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn ul li {
    font-size: 13px;
  }
  #home #banner-body-main-mqn .content-detail-about-us-mqn h2 {
    font-size: 14px;
  }
  #home #banner-body-main-mqn .sologanMqn {
    font-size: 16px;
  }
  #home .item-title-services img {
    width: 60%;
  }
  #home .video-main-about-mqn video {
    height: auto;
  }
  #home .item-content-about-main h1 {
    font-size: 26px;
  }
  #home .title-banner-product img,
  #home .title-about-main img {
    width: 60%;
  }
  #home #footter-main-pro-mqn .item-address-pro h1 {
    font-size: 20px;
  }
  #home #footter-main-pro-mqn .item-company-adrress-mqn p {
    font-size: 12px;
  }
  #home #footter-main-pro-mqn .title-contact-main-pro img {
    width: 60%;
  }
  .ant-modal-title {
    font-size: 12px;
    font-weight: bold;
  }
  .ant-modal-content {
    z-index: 9999999999 !important;
    position: relative !important;
  }
  .ant-drawer-left.ant-drawer-open {
    display: block;
  }
}
